<template>
  <div class="ph-inpage-widget ph-inpage-widget-bottom-right ph-inpage-widget--dynamic-float-motion">
            <div class="ph-inpage-widget__body">
                <div class="ph-inpage-widget__content">
                    <div class="ph-inpage-widget__txt">
                        <div class="ph-inpage-widget__title ph-inpage-widget__txt-overflow-1">{{adTitle}}</div>
                        <div class="ph-inpage-widget__desc ph-inpage-widget__txt-overflow-2">{{adText}}</div>
                    </div>
                    <div class="ph-inpage-widget__triangle2"></div>
                </div>
                <div class="ph-inpage-widget__circle ph-inpage-widget__open-content">
                    <div class="ph-inpage-widget__circle-thumb">
                      <img :src="require('@/assets/panel/images/inpage/empty.svg')"     v-show="!imgIcon" alt="" />
                    <img :src="imgIcon" v-show="imgIcon" />
                      </div>
                    <div class="ph-inpage-widget__close">✕</div>
                </div>
            </div>
        </div>
</template>

<script>
export default {
  props: ['adTitle','adText','imgIcon']
}
</script>